import { createSlice } from '@reduxjs/toolkit';
import { initialGlobalState } from './initialState';
import { setStorageItem } from '../../helpers/localStorage';
import { toLowerKeys } from '../../helpers/utils';

export const globalSlice = createSlice({
    name: 'global',
    initialState: initialGlobalState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setProfileLoading(state, action) {
            state.isProfileLoading = action.payload;
        },
        setLanguage(state, action) {
            state.language = action.payload;
            setStorageItem('language', action.payload);
        },
        setLoyaltyPoints(state, action) {
            const { LoyaltyPoints, BonusRolls } = action.payload;
            state.currentUser.LoyaltyPoints = LoyaltyPoints;
            state.currentUser.BonusRollCount = BonusRolls;
        },
        setScopelyLogin(state, action) {
            state.scopelyLogin = action.payload;
        },
        setFBLogin(state, action) {
            state.fbLogin = action.payload;
        },
        setTabsLoading(state, action) {
            state.tabsLoading = action.payload;
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
            state.authenticated = !action?.payload ? false : true;
            state.loggedIn = !action?.payload ? false : action?.payload?.UserId;
        },
        setAuthenticated(state, action) {
            state.authenticated = action.payload;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload;
        },
        setTheme(state, action) {
            state.theme = action.payload;
        },
        setModal(state, action) {
            state.modal = action.payload;
        },
        setInterstitials(state, action) {
            state.interstitials = action.payload;
        },
        setPortalConfigs(state, action) {
            state.globalConfig = action.payload;
            state.maintenance = {
                global: true,
                home: true,
                shop: true,
                webshop: true,
                playerProfile: true,
                factionProfile: true,
                news: true,
                calendar: true,
                events: true,
            };
        },
        setFacebookData(state, action) {
            state.fbData = action.payload;
        },
        setPopUps(state, action) {
            state.popUps = action.payload;
        },
        setFtue(state, action) {
            state.ftue = action.payload;
        },
        setShowTour(state, action) {
            state.showTour = action.payload;
        },
        setConfigs(state, action) {
            setStorageItem('app_version', action.payload?.MinVersion);
            action.payload.appLoaded = true;
            state.configs = action.payload;
        },
        setNotFound(state, action) {
            state.notFound = action.payload;
        },
        setSidebar(state, action) {
            const { side, open } = action.payload;
            state[side] = open;
        },
        setFeatures(state, action) {
            state.features = action.payload && toLowerKeys(action.payload);
        },
        setGameConfigData(state, action) {
            state.gameConfig.data = action.payload;
        },
        setGameConfigError(state, action) {
            state.gameConfig.error = action.payload;
        },
        setGameConfigStatus(state, action) {
            state.gameConfig.status = action.payload;
        },
    },
});
