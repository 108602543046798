import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';

import FeaturedSkeleton from './FeaturedSkeleton';
import SectionTitle from '../SectionTitle/SectionTitle';
import FeaturedSlider from './FeaturedSlider';
import LogOutState from '../LogOutState/LogOutState';

import { globalSel } from '../../../store/global';
import { shopSel } from '../../../store/shop';

import featured_background from '../../../assets/images/LogOut/featured_background.png';
import featured_background_sm from '../../../assets/images/LogOut/featured_background-sm.png';
import featured_logo from '../../../assets/images/LogOut/featured_logo.png';
import featured_additional from '../../../assets/images/LogOut/featured_additional.png';

function FeaturedSliderWrapper({ selector = 'featuredOffersSelector', className }) {
    const t = useTranslation();

    const theme = useSelector(globalSel.themeSelector);
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    const currentUser = useSelector(globalSel.currentUserSelector);
    const section = useSelector(shopSel[selector]);
    const isLoading = useSelector(shopSel.isLoading);

    const data = section?.StoreFront?.Listings || [];
    const endDate = section?.EndDate;

    return (
        data.length > 0 && (
            <Box
                component="section"
                className={`WP-featured WP-hide-empty-space ${className}`}
                pb={[10, 12, 12, 16, 20]}
            >
                {isLoading ? (
                    <FeaturedSkeleton />
                ) : (
                    <>
                        {currentUser ? (
                            <Box>
                                {!data?.length ? null : (
                                    <>
                                        <SectionTitle
                                            title={t('featured.offers')}
                                            subtitle={t('featured.offers.subtitle')}
                                            customIcon="feature-offers"
                                        />

                                        <FeaturedSlider data={data} endDate={endDate} />
                                    </>
                                )}
                            </Box>
                        ) : (
                            <>
                                <SectionTitle
                                    title={t('featured.offers')}
                                    subtitle={t('featured.offers.subtitle')}
                                    icon="icon-star"
                                />
                                <LogOutState
                                    bgImage={
                                        breakpointsSM ? featured_background : featured_background_sm
                                    }
                                    mainImage={featured_logo}
                                    additionalImage={featured_additional}
                                    section="featured_offers"
                                    isLogOutState
                                />
                            </>
                        )}
                    </>
                )}
            </Box>
        )
    );
}

FeaturedSliderWrapper.propTypes = {
    selector: PropTypes.string,
    className: PropTypes.string,
};

export default FeaturedSliderWrapper;
