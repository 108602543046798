import React from 'react';
import PropTypes from 'prop-types';

import SwiperSlider from '../SwiperSlider/SwiperSlider';
import FeaturedSliderItem from './FeaturedSliderItem';

const FeaturedSlider = ({ data, endDate }) => {
    const delay = 5000;

    if (!data) return false;

    return (
        <SwiperSlider
            navigation
            spaceBetween={32}
            itemwidth="100%"
            className="WP-slider-spacing"
            pagination={{
                clickable: true,
            }}
            allowTouchMove
            autoplay={{ delay, disableOnInteraction: false, stopOnLastSlide: false }}
        >
            {data.map((element, index) => {
                const sliderItem =
                    element?.StoreListing?.availableCount <= 0 &&
                    element?.StoreListing?.FallbackListing
                        ? element?.StoreListing?.FallbackListing
                        : element?.StoreListing;

                return (
                    <>
                        <FeaturedSliderItem
                            key={element?.Name}
                            item={sliderItem}
                            commodity={element?.StoreListing?.Debit?.Adjustments[0].CommodityKey}
                            endDate={endDate}
                        />
                    </>
                );
            })}
        </SwiperSlider>
    );
};

FeaturedSlider.propTypes = {
    data: PropTypes.array,
    endDate: PropTypes.string,
};

export default FeaturedSlider;
