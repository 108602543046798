import React from 'react';

import { Box } from '@material-ui/core';

import SectionTitle from '../SectionTitle/SectionTitle';
import { useTranslation } from '../../../hooks/useTranslation';

import LatestUpdatesSlider from './LatestUpdatesSlider';
import { getSlicedArray } from '../../../helpers/utils';
import { latestUpdates } from './__mockData';

const HomeLatestUpdates = () => {
    const t = useTranslation();

    const filteredData = getSlicedArray(latestUpdates, 0, 3);

    return (
        <Box component="section" mt={(5, 6, 6, 8, 10)} pb={[10, 12, 12, 16, 20]}>
            <SectionTitle title={t('welcome')} />
            <LatestUpdatesSlider data={filteredData} />
        </Box>
    );
};

export default HomeLatestUpdates;
